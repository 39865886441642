<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :key="tableKey"
      :pagination.sync="pagination"
      disable-initial-sort
      rowKey
    >
    <template slot="items" slot-scope="props">
      <tr @click="clicked(props.item)" :class="{'secondary': props.item[rowKey]===selectedCode}">
        <td v-for="header in headers" :key="header.value">
          <BaseTableColumn
            :item="props.item"
            :index="header.value"
            :format="header.format"
          />
        </td>
      </tr>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseTable',
  props: {
    headers: Array,
    items: Array,
    search: String,
    tableKey: String,
    rowKey: String,
  },
  data: () => ({
    pagination: {
      rowsPerPage: 10,
      totalItems: -1,
    },
    selectedCode: -1,
    isPaging: false,
  }),
  computed: {
    tableRows() {
      const rows = [...this.items];
      return rows;
    },
    ...mapGetters('app', ['getStickyTenant']),
    ...mapGetters('users', ['getCurrent']),
  },
  components: {
    BaseTableColumn: () => import('@/components/base/BaseTableColumn'),
  },
  methods: {
    clicked(row) {
      window.scrollTo(0, 0);
      this.selectedCode = row[this.rowKey];
      // this.$set(row, 'selected', true);
      this.$emit('rowClick', row);
    },
    highlightRow(targetIndex) {
      this.selectedCode = this.items[targetIndex][this.rowKey];
    },
    setPage(tenantIndex) {
      if (tenantIndex) {
        const targetPage = Math.ceil(tenantIndex / this.pagination.rowsPerPage);
        this.pagination.page = targetPage;
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.isPaging = true;
      },
    },
  },
  updated() {
    if (this.selectedCode === -1 && (typeof this.items === 'object') && this.items.length > 0) {
      this.highlightRow(0);
    }
    // This is a one-off hack to highlight the selected tenant row when this base table is being
    // used on the Tenant page.
    const vm = this;
    if (!this.isPaging && this.headers[0].text === 'Tenant Code' && this.headers[1].text === 'Tenant ID') {
      // Get the index of the selected tenant from this.tableRows.
      const tenantIndex = vm.tableRows.findIndex(row => row.code === vm.getStickyTenant);
      this.highlightRow(tenantIndex);
      this.setPage(tenantIndex);
    } else if (!this.isPaging && this.headers[0].text === 'Username') {
      // Get the index of the selected tenant from this.tableRows.
      let userIndex;
      if (vm.getCurrent.userId) {
        userIndex = vm.tableRows.findIndex(row => row.userId === vm.getCurrent.userId);
      } else {
        userIndex = 0;
      }
      this.highlightRow(userIndex);
      this.setPage(userIndex);
    }
    if (this.isPaging) {
      this.isPaging = false;
    }
  },
};
</script>
